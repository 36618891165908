



































import Vue from "vue";
import { Bio } from "@/constants";

export default Vue.extend({
  name: "Bio",
  data: () => ({
    Bio,
  }),
});
