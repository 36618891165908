import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{attrs:{"appear":"","name":"bio__fade"}},[_c(VRow,{key:"0",staticClass:"bio mt-10 mt-md-16",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VImg,{staticClass:"bio__image",attrs:{"src":_vm.Bio.image,"alt":"Spencer Krips"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1),_c(VCol,{staticClass:"bio__description",attrs:{"cols":"12","md":"6"}},[_c(VCard,{staticClass:"bio__description--paragraphs",attrs:{"flat":"","tile":"","color":"transparent"}},_vm._l((_vm.Bio.paragraphs),function(paragraph,index){return _c(VCardText,{key:index,staticClass:"bio__description--paragraph pb-0 pb-md-4 pt-md-0",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(paragraph)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }